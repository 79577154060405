%ant-input {
  background: var(--white);
  border: 1px solid var(--black-25);
  box-sizing: border-box;
  border-radius: 6px;
  width: 100%;
  height: 45px;
  padding: 0 15px;
  font-size: 16px;
  line-height: 22px;

  &:hover {
    border: 1px solid var(--black-75);
  }

  &.focus,
  &:focus {
    box-shadow: none;
    border: 2px solid var(--black-75);
    border-right-width: 2px !important;
    padding: 0 14px;
  }
}

.ant-form-item {
  margin-bottom: 20px;

  .ant-form-item-explain {
    display: flex;
    align-items: center;
    position: absolute;
    top: -15px;
    right: 0.03rem;
    font-size: 11px;
    font-style: italic;
    min-height: auto;
    height: 12px;

    .ant-form-item-explain-error {
      line-height: 12px;
      margin-right: 3px;
      padding-right: 6px;
      border-right: 1px solid var(--black-25);

      &:last-child {
        padding-right: 0;
        margin-right: 0;
        border-right: 0;
      }
    }
  }

  .ant-form-item-label {
    padding-bottom: 5px;
  }

  label {
    margin-bottom: 5px;
    font-weight: var(--font-semi-bold);
    font-size: 16px;
    line-height: 22px;
  }

  .ant-input {
    @extend %ant-input;

    &.ant-input-disabled {
      background: #f5f5f5;
      border-color: var(--black-25);

      &:hover {
        border-color: var(--black-25);
      }
    }
  }

  textarea.ant-input {
    resize: none;
    padding-top: 10px;

    &:focus {
      padding-top: 9px !important;
    }
  }

  .ant-input-number {
    @extend %ant-input;

    .ant-input-number-handler-wrap {
      overflow: hidden;
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
    }

    .ant-input-number-input {
      height: 43px;
      padding: 0;
    }

    &:focus,
    &.ant-input-number-focused {
      box-shadow: none;
      border: 2px solid var(--black-75);
      border-right-width: 2px !important;
      padding: 0 14px;

      .ant-input-number-input {
        height: 41px;
      }
    }

    &-disabled {
      background: #f5f5f5;
      border-color: var(--black-25);

      &:hover {
        border-color: var(--black-25);
      }
    }
  }

  .ant-input-affix-wrapper {
    @extend %ant-input;
    padding: 0;
    position: relative;

    .ant-input {
      border: none;
      height: 43px;
      padding: 0 45px 0 15px;

      &:hover {
        border: none;
      }

      &:focus {
        height: 41px;
        border: none;
      }
    }

    .ant-input-suffix {
      position: absolute;
      right: 0;
      top: 0;
      height: 43px;
      line-height: 43px;
      padding: 0 15px;
      margin: 0;
    }

    &.ant-input-affix-wrapper-focused {
      box-shadow: none;
      border: 2px solid var(--black-75);
      border-right-width: 2px !important;

      .ant-input {
        padding: 0 44px 0 14px;
      }

      .ant-input-suffix {
        height: 41px;
        line-height: 41px;
        padding: 0 14px;
      }
    }
  }

  &.ant-form-item-has-error {
    .ant-input {
      border: 2px solid var(--red);

      &:hover {
        border-right-width: 2px !important;
      }

      &:focus {
        box-shadow: none !important;
        padding: 0 15px;
      }

      &:not(.ant-input-disabled):not(.ant-input-borderless) {
        border-right-width: 2px !important;
        border-color: var(--red);
      }
    }

    .ant-input-number {
      border: 2px solid var(--red);

      &:hover {
        border-right-width: 2px !important;
      }

      &:focus {
        box-shadow: none !important;
        padding: 0 15px;
      }
    }

    .ant-input-affix-wrapper {
      border: 2px solid var(--red);
      box-shadow: none !important;

      .ant-input {
        border: none;
        height: 41px;
      }

      &:hover {
        border-right-width: 2px !important;
      }
    }

    :not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:focus {
      border: 2px solid var(--red);
      border-right-width: 2px !important;

      .ant-input-suffix {
        height: 41px;
        line-height: 41px;
        padding: 0 14px;
      }
    }
  }

  &.disabled {
    cursor: not-allowed;
    border-color: var(--black-25);

    &:hover {
      border-color: var(--black-25);
    }
  }
}

.ant-radio-group {
  .ant-radio-wrapper {
    display: inline-flex;
    align-items: center;

    .ant-radio {
      .ant-radio-inner {
        width: 30px;
        height: 30px;
        box-shadow: none;

        &::after {
          width: 34px;
          height: 34px;
          top: 5px;
          left: 5px;
          background-color: var(--black);
        }
      }

      &.ant-radio-checked {
        .ant-radio-inner {
          border-color: var(--black);
        }
      }

      &:hover {
        .ant-radio-inner {
          border-color: var(--black);
        }
      }
    }
  }
}

.ant-picker {
  @extend %ant-input;
  width: 100%;
  padding: 0;

  .ant-picker-input {
    height: 100%;
    position: relative;

    & > input {
      font-size: 16px;
      line-height: 22px;
      height: 100%;
      padding: 0 15px;
    }

    .ant-picker-suffix {
      position: absolute;
      right: 15px;
    }
  }

  &-dropdown {
    .ant-picker-panel-container {
      border-radius: 6px;

      .ant-picker-panel {
        border: 1px solid var(--black-25);
        box-sizing: border-box;
        border-radius: 6px;

        .ant-picker-date-panel {
          width: 301px;

          .ant-picker-header {
            border-bottom: 1px solid var(--black-25);

            button {
              color: var(--black-40);

              &:hover {
                color: var(--black);
              }
            }

            .ant-picker-header-view {
              color: var(--black);
              font-weight: var(--font-bold);
              font-size: 16px;
            }
          }

          .ant-picker-body {
            padding: 0;

            .ant-picker-content {
              th,
              td {
                font-weight: var(--font-semi-bold);
                font-size: 16px;
                line-height: 22px;
                width: 43px;
                height: 30px;
                border-right: 1px solid var(--black-25);

                &:last-child {
                  border-right: none;
                }
              }

              thead {
                tr {
                  border-bottom: 1px solid var(--black-25);

                  th {
                    color: var(--black-40);
                    background: var(--black-75);
                    color: var(--white);
                  }
                }
              }

              tbody {
                tr {
                  border-bottom: 1px solid var(--black-25);

                  &:last-child {
                    border-bottom: none;
                  }

                  .ant-picker-cell {
                    color: var(--black-25);
                    padding: 0;

                    .ant-picker-cell-inner {
                      width: 100%;
                      height: 100%;
                      border-radius: 0;
                      display: flex;
                      align-items: center;
                      justify-content: center;

                      &::before {
                        display: none;
                      }
                    }

                    &:hover {
                      .ant-picker-cell-inner {
                        background: var(--black-50) !important;
                        color: var(--white);
                      }
                    }

                    &.ant-picker-cell-disabled {
                      cursor: not-allowed;
                      background: var(--black-10);

                      &:before {
                        background: transparent;
                      }
                    }

                    &.ant-picker-cell-in-view {
                      color: var(--black-75);
                    }

                    &.ant-picker-cell-today {
                      .ant-picker-cell-inner {
                        background-color: var(--black-25);
                      }
                    }
                  }
                }
              }
            }
          }
        }

        .ant-picker-footer {
          border-top: 1px solid var(--black-25);

          .ant-picker-today-btn {
            color: var(--black-40);
            font-weight: var(--font-bold);

            &:hover {
              color: var(--black);
            }
          }
        }
      }
    }
  }

  &.ant-picker-disabled {
    border-color: var(--black-25);

    &:hover {
      border-color: var(--black-25);
    }
  }
}

.ant-select {
  &:not(.ant-select-customize-input) {
    .ant-select-selector {
      background: var(--white);
      border: 1px solid var(--black-25);
      box-sizing: border-box;
      border-radius: 6px;
      height: 45px;
      padding: 0 15px;

      span {
        font-size: 16px;
        line-height: 42px;

        &.ant-select-selection-search {
          left: 15px;
        }
      }
    }
  }

  &:hover {
    &:not(.ant-select-customize-input) {
      .ant-select-selector {
        border-color: var(--black);
      }
    }
  }
}

.ant-select-focused {
  &.ant-select {
    &:not(.ant-input-disabled):not(.ant-select-customize-input) {
      .ant-select-selector {
        box-shadow: none !important;
        border: 1px solid var(--black-25) !important;

        span {
          line-height: 40px;
        }
      }
    }
  }
}

.ant-select-dropdown {
  padding: 0;
  box-shadow: none;
  border: 1px solid var(--black-25);
  border-radius: 6px;

  .ant-select-item-option {
    font-size: 13px;
    padding: 10px 15px;
    border-bottom: 1px solid var(--black-25);

    &:last-child {
      border-bottom: none;
    }
  }

  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background-color: var(--black-25);
    color: var(--white);
  }

  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: var(--black-50);
    color: var(--white);
  }
}
