.no-tsm-section {
  display: flex;
  min-height: calc(100vh - 460px);
  justify-content: center;
  align-items: center;

  .ant-result {
    background: var(--white);
    border-radius: 10px;
  }
}

.dashboard-wrapper {
  .main-title {
    margin-bottom: 20px;
  }

  .ant-divider {
    margin: 60px 0;
  }

  &__rc-list {
    &__actions {
      display: flex;

      &__filter {
        margin-left: 20px;
        width: 650px;
        position: relative;
        display: flex;

        .ant-form-item {
          flex-flow: column;
          margin: 0;
          flex: 1;
          margin-right: 40px;

          &:last-child {
            margin-right: 0;
          }

          .ant-form-item-label {
            padding: 0;
            margin: 0;
            text-align: left;
            position: absolute;
            top: -23px;

            label {
              margin: 0;
              font-size: 16px;
              line-height: 21px;
              height: auto;
            }
          }

          &.ant-form-search {
            .ant-input-affix-wrapper {
              .ant-input {
                &:focus {
                  height: 43px !important;
                  border-width: 1px;
                }

                &:focus {
                  height: 43px;
                  border-width: 1px;
                }
              }

              &.ant-input-affix-wrapper-focused,
              &:focus {
                padding: 0;
                border-width: 1px;
                border-right-width: 1px !important;
              }
            }
          }
        }
      }
    }

    .rc-list-table {
      margin-top: 40px;
      margin-bottom: 20px;

      .table-link {
        color: inherit;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
