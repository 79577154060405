.app-container {
  width: 100%;
  max-width: 1080px;
  margin-right: auto;
  margin-left: auto;
  position: relative;

  @media (max-width: 1120px) {
    width: calc(100% - var(--app-gutter-x, 0.75rem) - var(--app-gutter-x, 0.75rem));
    margin-right: var(--app-gutter-x, 0.75rem);
    margin-left: var(--app-gutter-x, 0.75rem);
  }
}
