@import "../../assets/styles/mixins";

.app-modal {
  .ant-modal-body {
    position: relative;
  }

  &__message {
    border-radius: 20px;

    &-wrapper {
      position: absolute;
      left: 0;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      color: var(--white);
      font-weight: var(--font-extra-bold);
    }

    // color: var(--white);
    // font-weight: var(--font-extra-bold);
    // border-radius: 20px;
    // padding: 8px 20px 12px 20px;
    // font-size: 22px;
    // line-height: 30px;

    // &__error {
    //   background: var(--red-dark);
    //   display: flex;

    //   span {
    //     letter-spacing: 5px;
    //   }

    //   // em
    // }

    // &__success {
    //   background: #eee2b6;
    //   color: var(--black);
    //   padding: 25px;
    //   top: -45px;

    //   &__wrapper {
    //     position: relative;
    //     display: flex;
    //     align-items: center;

    //     .anticon {
    //       &.anticon-check {
    //         color: #47740d;
    //         font-size: 30px;
    //         margin-right: 15px;
    //       }
    //     }

    //     &__close {
    //       width: 44px;
    //       height: 44px;
    //       position: absolute;
    //       top: -47px !important;
    //       left: 50%;
    //       transform: translateX(-50%);
    //       background-color: #887d54 !important;
    //       border: 2px solid var(--white);
    //       border-radius: 50%;
    //       display: flex;
    //       align-items: center;
    //       justify-content: center;
    //       color: var(--white);
    //       font-size: 18px;
    //       cursor: pointer;

    //       &:hover {
    //         background-color: #615836 !important;
    //       }
    //     }

    //     em {
    //       font-size: 30px;
    //       line-height: 41px;
    //     }
    //   }
    // }
  }

  &__title {
    font-size: 36px;
    line-height: 45px;
    color: --var(--black);
    margin-bottom: 20px;

    svg {
      margin-right: 10px;
    }
  }

  &__sub-title {
    font-size: 18px;
    line-height: 26px;
    font-weight: var(--font-bold);
    text-transform: uppercase;
    margin: 0;
    color: --var(--black);
  }

  &__content {
    .ant-spin-nested-loading > div > .ant-spin {
      max-height: inherit;
    }
  }

  .ant-modal-content {
    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    position: relative;

    .ant-modal-close {
      position: absolute;
      right: -20px;
      top: -20px;
      font-size: 20px;
      color: var(--white);
      cursor: pointer;
      background-color: var(--black-75);
      width: 45px;
      height: 45px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      border-radius: 50%;
      border: 2px solid var(--white);
      @include transition(background-color 0.3s linear);

      &:hover {
        background-color: var(--black-40);
      }
    }

    .ant-modal-body {
      padding: 60px 90px;

      p {
        font-size: 18px;
        line-height: 30px;
        color: --var(--black);
      }
    }
  }

  &.app-modal-error {
    .ant-modal-content {
      border: 4px solid var(--red-dark);

      .app-modal__message-wrapper {
        top: -30px;

        .app-modal__message {
          display: flex;
          align-items: center;
          background: var(--red-dark);
          height: 54px;
          font-size: 22px;
          line-height: 30px;
          padding: 0 18px;

          span {
            letter-spacing: 5px;
            font-size: 25px;
          }

          em {
            margin: 0 19px;
          }
        }
      }
    }
  }

  &.app-modal-success {
    .ant-modal-content {
      .app-modal__message-wrapper {
        top: -45px;

        .app-modal__message {
          background: #eee2b6;
          color: var(--black);
          padding: 25px;

          .anticon {
            &.anticon-check {
              color: #47740d;
              font-size: 30px;
              margin-right: 15px;
            }
          }

          .ant-modal-close {
            width: 44px;
            height: 44px;
            position: absolute;
            top: -22px !important;
            left: 50%;
            transform: translateX(-50%);
            background-color: #887d54 !important;
            border: 2px solid var(--white);
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            color: var(--white);
            font-size: 18px;
            cursor: pointer;

            &:hover {
              background-color: #615836 !important;
            }
          }

          em {
            font-size: 30px;
            line-height: 41px;
          }
        }
      }
    }
  }
}

.ant-modal-root .ant-modal-mask {
  background-color: rgba(0, 0, 0, 0.95);
}
