.referral-center-detail-section {
  padding-top: 30px;
  padding-bottom: 85px;

  &__kpi {
    margin-bottom: 40px;

    &__item {
      display: flex;
      align-items: center;
      height: 90px;
      border-radius: 20px;
      background: var(--black-75);
      color: var(--white);
      font-weight: var(--font-semi-bold);
      font-size: 22px;
      line-height: 26px;
      padding: 0 40px 0;

      strong {
        font-weight: var(--font-extra-bold);
        font-size: 46px;
        text-align: center;

        small {
          font-size: 46px;
        }
      }

      span {
        flex: 1;
        text-align: center;
      }
    }
  }

  &__details {
    height: 330px;
    line-height: 26px;
    padding: 30px 36px 0 36px;
    display: flex;
    flex-direction: column;

    &__top {
      margin-bottom: 20px;
    }

    &__name {
      font-size: 22px;
      font-weight: var(--font-bold);
    }

    &__poc-name {
      font-weight: var(--font-bold);
      margin-bottom: 5px;
    }

    &__address {
      margin-bottom: 5px;
    }

    &__id {
      font-size: 14px;
      font-weight: var(--font-bold);
    }

    &__contact {
      margin-bottom: 4px;

      span {
        font-weight: var(--font-bold);
        margin-right: 5px;
      }

      .app-link {
        font-weight: var(--font-normal);
        border-bottom: none;
      }
    }

  }

  &__notes {
    display: flex;
    flex-direction: column;
    position: relative;

    &__scrollbar {
      max-height: 430px !important;
      position: relative;

      .simplebar-track {
        &.simplebar-vertical {
          width: 15px;
          background: #C4C4C4;
          border-radius: 60px;
          
          .simplebar-scrollbar {
            &.simplebar-visible {
              opacity: 1;
              
              &::before {
                background: #0A4A95;
                border-radius: 60px;
                opacity: 1;
                left: 0;
                right: 0;
              }
            }
          }
        }
      }
    }

    &__item {
      margin-bottom: 20px;
      margin-right: 33px;
      position: relative;

      &:last-child {
        margin-bottom: 5px;
      }

      &__delete {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        background: var(--red-75);
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 50px;

        &__actions {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 164px;

          .app-button {
            font-size: 16px;
            font-weight: 800;

            &.ant-btn-primary {
              width: 100%;
              height: 48px;
              margin-bottom: 7px;

              &:hover {
                background: var(--pure-back);
                border-color: var(--pure-back);
              }
            }
  
            &.ant-btn-link {
              color: var(--white);
              border-bottom-color: var(--white);

              &:hover {
                color: var(--white);
                border-bottom-color: transparent;
              }
            }

            &[disabled] {
              color: var(--white);
              opacity: 0.8;
              pointer-events: none;
            }
          }
        }

        em {
          font-weight: var(--font-semi-bold);
          font-size: 16px;
          line-height: 22px;
          text-align: center;
          color: var(--white);
          display: inline-block;
          width: 195px;
        }
      }
    }

    &__add {
      position: absolute;
      left: 0;
      right: 33px;
      bottom: -40px;
      display: flex;
      justify-content: center;

      .ant-btn-link {
        font-weight: var(--font-normal);
      }
    }
  }
}
