.referral-center-follow-ups-section {
  padding-top: 30px;
  padding-bottom: 35px;
  background: var(--black-75);

  h5 {
    font-weight: var(--font-bold);
    font-size: 16px;
    line-height: 22px;
    color: var(--white);
    margin: 0;
  }

  &__cards {
    margin-top: 14px;

    .app-button {
      &.ant-btn-link {
        margin-top: 30px;
        padding: 0;
        height: auto;
        font-size: 16px;
        line-height: 22px;
        color: var(--white);
        border-bottom-color: var(--white);

        &:hover {
          color: var(--white);
          border-bottom-color: transparent;
        }
      }
    }
  }
}
