.contact-history-section {
  max-width: 895px;
  margin: 0 auto;
  padding-top: 60px;

  &__loading {
   display: flex;
   flex-direction: column;
   align-items: center;

   .ant-spin {
     font-size: 18px;
   }

   span {
     display: block;
     font-size: 20px;
     margin-top: 10px;
   }
  }
}
