.app-layout,
.auth-layout {
  .app-footer {
    background: var(--black-75);
    color: var(--white);
    height: 160px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    line-height: normal;
    padding: 0;
    padding-right: var(--app-gutter-x, 0.75rem);
    padding-left: var(--app-gutter-x, 0.75rem);
    z-index: 9;

    &__logo {
      width: 142px;
      height: 49px;
      background: url("../../assets/images/logo.png") no-repeat center;
      background-size: cover;
      margin-bottom: 10px;
    }

    &__copyright {
      font-size: 14px;
      line-height: 19px;
      text-align: center;
    }
  }
}
