.app-style-guide {
  &.ant-layout {
    background: var(--white) !important;
  }

  h1.title {
    color: var(--red);
    font-size: 22px;
    line-height: 30px;
  }

  &__colors {
    &__item {
      width: 95px;
      height: 95px;
      background: #FFFFFF;
      border: 1px solid #929292;
      margin-left: 21px;

      &:first-child {
        margin-left: 0;
      }
    }

    .color-black {
      background: var(--black);
      border-color: var(--black);
    }

    .color-black-75 {
      background: var(--black-75);
      border-color: var(--black-75);
    }

    .color-black-50 {
      background: var(--black-50);
      border-color: var(--black-50);
    }

    .color-black-25 {
      background: var(--black-25);
      border-color: var(--black-25);
    }
  }

  &__fonts {
    &__secondary {
      font-family: var(--secondary-font-family);
    }

    &__semi-bold {
      font-weight: var(--font-semi-bold);
    }

    &__bold {
      font-weight: var(--font-bold);
    }

    &__extra-bold {
      font-weight: var(--font-extra-bold);
    }

    h1 {
      margin: 0;
      margin-left: 50px;
      font-size: 20px;
      text-transform: uppercase;

      &:first-child {
        margin-left: 0;
      }
    }
  }

  .app-cards {}
}
