body {
  font-family: var(--default-font-family);
  font-weight: var(--font-normal);
  font-size: var(--default-font-size);
  line-height: var(--default-line-height);
  background: var(--default-bg);
  color: var(--black);
  font-weight: var(--font-normal);
  margin: 0;
  position: relative;
  height: 100%;

  h1,
  h2,
  h3,
  h4,
  h5,
  p {
    color: var(--black);
  }

  .ant-layout {
    background: var(--default-bg);
  }

  .ant-divider {
    border-top: 1px solid #bbb5ad;
  }

  .ant-message {
    top: 0;
    display: flex;
    justify-content: flex-end;
  }

  .ant-alert {
    padding: 8px 12px;
  }

  .app-flex {
    display: flex;

    &__column {
      flex-direction: column;
    }

    &__row {
      flex-direction: row;
    }

    &__center {
      justify-content: center;
    }

    &__space-between {
      justify-content: space-between;
    }

    &__space-around {
      justify-content: space-around;
    }

    &__space-evenly {
      justify-content: space-evenly;
    }

    &__align-items-center {
      align-items: center;
    }
  }

  .app-link {
    line-height: 21px;
    display: inline-block;
    color: var(--blue-dark);
    font-weight: var(--font-bold);
    border-bottom: 1px solid var(--blue-dark);

    &:hover {
      color: var(--blue-dark-hover);
      border-bottom: 1px solid var(--blue-dark-hover);
    }
  }

  .capitalize {
    text-transform: capitalize;
  }

  .uppercase {
    text-transform: uppercase;
  }

  .lowercase {
    text-transform: lowercase;
  }

  .text-center {
    text-align: center;
  }

  .text-right {
    text-align: right;
  }

  .main-title {
    font-size: 36px;
    line-height: 49px;
    font-weight: var(--font-normal);
    color: var(--black);
    margin: 0;
    padding: 0;
  }

  .no-select {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
  }
}
