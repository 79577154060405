.square-card {
  background: var(--white);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);

  &__header {
    border-bottom: 1px solid var(--black-20);
    margin: 0 20px;
    padding-top: 20px;
    height: 60px;
    display: flex;
    justify-content: space-between;

    &__title {
      font-weight: var(--font-bold);
      font-size: 18px;
      line-height: 26px;
      color: var(--black-20);
      display: flex;
    }

    &__actions {
      display: flex;

      &__item {
        width: 60px;
        text-align: center;
        border-left: 1px solid var(--black-20);

        svg {
          color: var(--blue-dark);
          cursor: pointer;

          &:hover {
            color: var(--blue-dark-hover);
          }
        }
      }
    }
  }

  &__body {
    padding: 20px;
  }
}
