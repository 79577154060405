.login-form {
  .login-form-header {
    text-align: center;
    margin-bottom: 17px;

    img {
      width: 100%;
      margin-bottom: 35px;
    }

    h1 {
      color: inherit;
      font-family: var(--secondary-font-family);
      font-size: 32px;
      letter-spacing: 0.03em;
      margin: 0;
    }
  }

  form {
    max-width: 254px;
    margin: 0 auto;

    .ant-form-item {
      .ant-form-item-explain {
        position: relative;
        top: auto;
      }
    }

    button {
      margin-top: 5px;
      margin-bottom: 20px;
    }
  }
}
