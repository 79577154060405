.app-layout {
  .app-header {
    background: var(--black-75);
    color: var(--white);
    width: 100%;
    min-height: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    line-height: normal;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    padding: 0;
    z-index: 9;

    .header-content {
      height: 100px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &__left {
      display: flex;
    }

    &__logo_wrapper {
      color: inherit;
      display: inline-flex;
      align-items: center;

      h1 {
        color: inherit;
        font-family: var(--secondary-font-family);
        font-size: 22px;
        letter-spacing: 0.03em;
        margin: 0;
      }

      h3 {
        margin: 0;
        font-style: italic;
        font-weight: 300;
        font-size: 36px;
        line-height: 49px;
        margin-left: 16px;
      }
    }

    &__logo {
      width: 163px;
      height: 56px;
      background: url("../../assets/images/logo.png") no-repeat center;
      background-size: cover;
      margin-right: 20px;
    }

    &__right {
      align-self: center;
      font-family: var(--default-font-family);

      .ant-divider {
        height: 30px;
        border-color: #bcbcbc;
        margin: 0 20px;
      }
    }

    &__username {
      font-size: 16px;
      line-height: 22px;
      letter-spacing: 0.03em;
      color: var(--white);
    }

    &__logout {
      font-weight: var(--font-extra-bold);
      font-size: 12px;
      line-height: 16px;
      text-align: center;
      letter-spacing: 0.03em;
      color: var(--white);
      text-transform: uppercase;

      &:hover {
        color: var(--black-25);
      }
    }

    &-executive {
      background: var(--white);
      color: var(--black);

      .app-header__username {
        color: var(--black);
      }

      .app-header__logout {
        color: var(--blue-dark);

        &:hover {
          color: var(--blue-dark-hover);
        }
      }
    }

    &__select-tsm-user {
      background: #2a3128;
      color: var(--white);
      width: 100%;

      &__wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 80px;

        label {
          font-weight: var(--font-bold);
          font-size: 18px;
          line-height: 25px;
          margin-right: 15px;
        }

        .ant-select {
          width: 347px;
        }
      }
    }
  }
}
