.ad-hoc-follow-up-section {
  padding-top: 40px;
  padding-bottom: 60px;
  background: var(--white);
  border-bottom: 1px solid #BBB5AD;

  .rc-form {
    max-width: 895px;
    margin: 0 auto;
  }

  &__description-input {
    margin-bottom: 0 !important;

    .ant-input {
      height: 204px !important;
    }
  }
}
