.collapsible-wrapper {
  .ant-collapse {
    background: transparent;
    border: none;

    .ant-collapse-item {
      margin-bottom: 40px;
      background: var(--white);
      border-radius: 6px;
      border: none;

      &:last-child {
        margin-bottom: 0;
      }

      .ant-collapse-header {
        padding: 0;

        & > div {
          & > .anticon {
           &.ant-collapse-arrow {
             right: 20px;
             font-size: 50px;
             color: #B9B9B9;

             svg {
              transform: rotate(90deg);
             }
           }
          }
        }

        .header-content {
          position: relative;
          margin: 0 90px 0 80px;
          padding: 19px 0;
          width: 100%;

          &__icon {
            position: absolute;
            top: 0;
            bottom: 0;
            left: -113px;
            box-sizing: border-box;
            display: flex;
            align-items: center;

            .app-icon-block {
              height: 69px;
              width: 69px;

              &__icon {
                border: 4px solid var(--white);
              }
            }
          }

          &__text {
            display: flex;
            align-items: center;
            justify-content: space-between;

            &__heading {
              font-size: 36px;
              line-height: 40px;
            }

            &__date-time {
              font-size: 18px;
              line-height: 22px;
              text-align: right;
              min-width: 170px;
              max-width: 170px;

              span {
                display: block;
              }
            }
          }
        }
      }

      &.ant-collapse-item-active {
        .ant-collapse-header {
          & > div {
            & > .anticon {
             &.ant-collapse-arrow {

               svg {
                transform: rotate(-90deg) !important;
               }
             }
            }
          }
        }
      }

      .ant-collapse-content {
        border: none;
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;

        .ant-collapse-content-box {
          border-top: 1px solid #BBB5AD;
          margin: 0 90px;
          padding: 25px 0;
          font-size: 16px;
          line-height: 32px;
          color: var(--pure-back);
        }
      }
    }
  }
}
