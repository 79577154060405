.app-modal {
  &.add-edit-rc-modal {
    .ant-modal-body {
      .rc-form {
        &__inputs {
          overflow: hidden;
          overflow-y: auto;
          max-height: calc(100vh - 320px);

          .ant-form-item {
            .ant-input[disabled] {
              background-color: var(--black-10);
            }
          }
        }

        &__actions {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          margin-top: 20px;

          .ant-btn-primary {
            width: 164px;
            margin-left: 20px;
          }

          .ant-btn-link {
            height: 28px;
          }
        }
      }
    }
  }
}
