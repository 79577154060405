.app-table-wrapper {
  &.ant-table-wrapper {
    .ant-table {
      table {
        border: 1px solid var(--black-75);
        border-collapse: collapse;

        th,
        td {
          border-color: var(--black-75);

          &::after {
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            width: 1px;
            background-color: var(--black-75);
            content: "";
          }

          &:last-child {
            &::after {
              display: none;
            }
          }
        }

        th {
          background-color: var(--black-75);
          color: var(--white);

          &::after {
            background-color: rgba(#fff, 0.2);
          }
        }

        tbody {
          tr {
            &:nth-child(odd) {
              td {
                background: #f2f2f2;
              }
            }
          }
        }
      }
    }
  }
}
