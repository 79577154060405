/****************
  SCSS Mixins
*/
/** Browser Compatibility **/
/* @include transition(background-color 1s .5s, color 2s); */
@mixin transition($transition...) {
  -moz-transition: $transition;
  -o-transition: $transition;
  -webkit-transition: $transition;
  transition: $transition;
}
