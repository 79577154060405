.app-icon-block {
  display: flex;
  align-items: center;

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 65px;
    height: 65px;
    border-radius: 50%;
    color: var(--white);
    background: var(--black-75);

    img {
      width: 31px;
      height: 31px;
    }
  }

  &__text {
    margin-left: 19px;
    color: var(--red);
    font-size: 22px;
    line-height: 30px;
  }
}
