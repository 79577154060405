.app-layout,
.auth-layout {
  .ant-layout-content {
    margin-top: 100px;
    padding-top: 60px;
    padding-bottom: 60px;
    min-height: calc(100vh - 260px);
  }
}

.auth-layout {
  background: #d0cac2 !important;

  .ant-layout-content {
    margin-top: 0;
    padding-top: 75px;
    padding-bottom: 75px;
    background: url("../../assets/images/auth-bg.jpg") no-repeat center;
    background-size: auto 100%;
    min-height: calc(100vh - 160px);

    .auth-box {
      width: 438px;
      background: rgba(255, 255, 255, 0.8);
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 20px;
      padding: 45px 33px;
    }
  }
}

.app-layout {
  &.app-layout-executive {
    .ant-layout-content {
      margin-top: 180px;
      padding-top: 60px;
      padding-bottom: 60px;
      min-height: calc(100vh - 340px);
    }
  }
}
