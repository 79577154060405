// root variables
:root {
  // colors
  --white: #ffffff;
  --pure-back: #000000;
  --black: #231f20;
  --black-75: #282d31;
  --black-50: #4e5359;
  --black-40: #53585c;
  --black-25: #a9afb4;
  --black-20: #B9B9B9;
  --black-10: #ebebeb;
  --black-5: #FAFAFA;
  --red: #ff0000;
  --red-dark: #952e2e;
  --red-75: #D71F1F;
  --blue: #407eaf;
  --blue-hover: #5d95c1;
  --blue-dark: #0A4A95;
  --blue-dark-hover: #3B91F5;
  --default-bg: #e3dfda;

  // fonts
  --default-font-family: "Open Sans", sans-serif;
  --secondary-font-family: "Alfa Slab One", sans-serif;
  --default-font-size: "18px";
  --default-line-height: "26px";
  --font-normal: 400;
  --font-semi-bold: 600;
  --font-bold: 700;
  --font-extra-bold: 800;

  --app-gutter-x: 20px;
}
