.uncompleted-follow-ups-section {
  &__cards {
    margin-top: 20px;

    // .app-link {
    //   &.show-more-link {
    //     margin-top: 20px;
    //   }
    // }
  }

  &__links {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;

    .app-link {
      &.show-more-link {
        line-height: 27px;
        font-size: 16px;
        font-weight: var(--font-bold);
      }
    }

    .ant-btn {
      &.ant-btn-link {
        line-height: 27px;
        font-size: 16px;
        font-weight: var(--font-bold);
      }
    }
  }
}
