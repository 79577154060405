/**
 * class: mb-1, mt-5, p-10, pl-3...
 * Specifies margin or padding for an element
 */
 @for $i from 0 through 10 {
  $gap_size: #{$i * 5};

  .m-#{$i} {
    margin: #{$gap_size}px !important;
  }

  .mt-#{$i} {
    margin-top: #{$gap_size}px !important;
  }

  .mb-#{$i} {
    margin-bottom: #{$gap_size}px !important;
  }

  .ml-#{$i} {
    margin-left: #{$gap_size}px !important;
  }

  .mr-#{$i} {
    margin-right: #{$gap_size}px !important;
  }

  .p-#{$i} {
    padding: #{$gap_size}px !important;
  }

  .pt-#{$i} {
    padding-top: #{$gap_size}px !important;
  }

  .pb-#{$i} {
    padding-bottom: #{$gap_size}px !important;
  }

  .pl-#{$i} {
    padding-left: #{$gap_size}px !important;
  }

  .pr-#{$i} {
    padding-right: #{$gap_size}px !important;
  }
 }

 @for $i from 0 through 100 {
  $gap_size: #{$i * 5};

  .w-#{$i} {
    width: #{$gap_size}px !important;
  }

  .h-#{$i} {
    height: #{$gap_size}px !important;
  }
}
