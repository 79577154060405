.ant-card.app-card {
  background: rgba(white, 0.2);
  border: none;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  height: 100%;
  cursor: pointer;

  &__red {
    background: #F5DDDD;
  }

  &__yellow {
    background: #EEE2B6;
  }

  &__blue {
    background: #C9D4DD;
  }

  &.app-card-no-data {
    background: #C9D4DD;

    .ant-empty {
      margin: 10px 8px;

      .ant-empty-image {
        color: var(--blue-dark);

        svg {
          width: 100px;
        }
      }

      .ant-empty-description {
        color: var(--blue-dark);
        font-size: 20px;
        font-weight: var(--font-semi-bold);
      }
    }
  }

  h1 {
    font-weight: var(--font-bold);
    font-size: 22px;
    line-height: 26px;
    margin: 0;
  }

  p {
    font-size: 18px;
    line-height: 26px;
    margin: 0;
    margin-top: 10px;

    span {
      display: block;
    }
  }

  h2 {
    font-weight: var(--font-bold);
    font-size: 18px;
    line-height: 26px;
    margin: 0;
    margin-top: 10px;
  }

  small {
    font-size: 14px;
  }
}
