.referral-center-details-page {
  position: relative;

  .app-link {
    .anticon {
      margin-right: 7px;
    }

    &.back-link {
      position: absolute;
      top: -40px;
      font-weight: var(--font-semi-bold);
    }
  }
}
