.follow-ups-page {
  .app-link {
    .anticon {
      margin-right: 7px;
    }

    &.back-link {
      position: absolute;
      top: -35px;
      font-weight: var(--font-semi-bold);
    }
  }

  &__content {
    &__cards {
      margin: 40px 0;
    }
  }
}

.complete-followup-modal {
  .client-detail {
    line-height: 26px;

    .detail-left {
      display: flex;
      flex-direction: column;
      font-weight: 700;

      .ant-divider-horizontal {
        margin: 15px 0;
      }

      .followup-title {
        font-size: 22px;
      }

      .followup-client {
        font-size: 18px;
      }

      .followup-client-street,
      .followup-client-address {
        font-weight: 500;
      }

      .followup-id {
        margin-top: 5px;
      }

      label {
        min-width: 250px;

        a {
          margin-left: 5px;
          color: var(--blue);
          font-weight: 500;

          &:hover {
            color: var(--blue-hover);
          }
        }
      }
    }
  }

  textarea.ant-input {
    height: 215px;
  }
}
