@import "../../assets/styles/mixins";

.app-button {
  &.ant-btn {
    border-radius: 90px;
    height: 48px;
    font-weight: var(--font-extra-bold);
    border: 2px solid transparent;
    @include transition(background-color 0.3s linear);
    padding-left: 23px;
    padding-right: 23px;

    svg {
      height: 12px;
    }

    &.ant-btn-default {
      border-color: var(--black-75);

      &:hover {
        color: var(--black-75);
        background-color: var(--black-10);
        border-color: var(--black-40);
      }
    }

    &.ant-btn-primary {
      background-color: var(--black-75);
      border-color: var(--black-75);

      &:hover {
        background-color: var(--black-40);
        border-color: var(--black-40);
      }
    }

    &.ant-btn-secondary {
      background-color: var(--blue);
      border-color: var(--blue);
      color: var(--white);

      &:hover {
        color: var(--white);
        background-color: var(--blue-hover);
        border-color: var(--blue-hover);
      }
    }

    &.ant-btn-link {
      color: var(--blue-dark);
      padding: 0;
      height: auto;
      border-bottom: 1px solid var(--blue-dark);
      border-radius: 0;

      &:hover {
        color: var(--blue-dark-hover);
        border-bottom: 1px solid var(--blue-dark-hover);
      }
    }
  }
}
