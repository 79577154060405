.no-role-page {
  display: flex;
  min-height: calc(100vh - 380px);
  justify-content: center;
  align-items: center;

  .ant-result {
    background: var(--white);
    border-radius: 6px;
  }
}
