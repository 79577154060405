.add-manual-follow-up-form {
  .ant-form-item {
    margin-bottom: 30px;
  }

  &__description-input {
    .ant-input {
      height: 315px !important;
    }
  }

  &__due-radio {
    .ant-radio-wrapper {
      width: 100%;
      display: flex;
      align-items: center;
      margin-right: 0;
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }

      span.ant-radio {
        top: 0;

        & + * {
          padding-right: 0;
          width: 100%;
        }
      }

      .ant-form-item {
        margin: 0;
      }
    }
  }

  &__actions{
    margin-top: 0 !important;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 20px;

    .ant-btn-primary {
      width: 164px;
      margin-left: 20px;
    }

    .ant-btn-link {
      height: 28px;
    }
  }
}
